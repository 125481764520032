@import './css/button';
@import './css/display';
@import './css/spacing';
@import './css/input';

:root {
  --primary-color: #373cd7;
  --secondary-color: #eef6ff;
}

html,
body,
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 13px;
  text-decoration: none;
  font-family: "Graphik",
    sans-serif;
  // font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  // Hide scrollbar for all browsers
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// Hide scrollbar for Chrome, Edge, and Safari
::-webkit-scrollbar {
  display: none;
}

/* text elipsis... */
.elipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app {
  width: 100%;
  min-height: 100vh;
  background: white;
  // background: var(--secondary-color);
  a {
    height: 100%;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.75);
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    svg {
      height: 18px;
      width: 18px;
    }
    &.active {
      color: var(--primary-color);    
    }
  }
  header, footer {
    width: 100%;
    min-height: 70px;
    background: white;
    .menu__content {
      width: 100%;
      margin: 0 auto;
      max-width: 1250px;
      position: relative;
      gap: 8px;
      .menu__list {
        height: 100%;
        a {
          border-bottom: 2px solid transparent;
          transition: all 0.3s ease;
          &.active {
            color: var(--primary-color);
            border-bottom: 2px solid var(--primary-color);
          }
        }
      }
      .mini__menu {
        width: 100vw;
        height: calc(100vh - 70px);
        position: fixed;
        left: 0;
        right: 0;
        top: 70px;
        background: rgba(0, 0, 0, 0.4);
      
        .mini__menu__container {
          width: 100%;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          border-top: .5px solid rgba(0, 0, 0, 0.1);
          background: white;
          padding: 8px 16px;
          display: flex;
          flex-wrap: wrap;
          align-items: start;
          justify-content: center;
          box-shadow: 4px 12px 10px 0px rgba(0, 0, 0, 0.05);
          z-index: 1;
          transition: all 0.3s ease;
          a {
            min-width: 100px;
            padding: 16px;
            border-radius: 4px;
            &.active {
              color: white;
              background: var(--primary-color);
            }
          }
        }
      }
    }
  }
  header {
    top: 0;
    z-index: 1;
    box-shadow: none;
    &.scrolled {
      box-shadow: 4px 12px 10px 0px rgba(0, 0, 0, 0.03);
    }
  }
  section {
    flex: 1;
    width: 100%;
  }
  footer {
    // box-shadow: 4px -12px 10px 0px rgba(0, 0, 0, 0.03);
    border-top: .5px solid rgba(0, 0, 0, 0.1);
    .footer {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(22%, 1fr));
      a {
        color: black;
      }
    }
    .footer__bottom {
      a {
        width: 32px;
        height: 32px;
        color: var(--primary-color);
        background: var(--secondary-color);
        transition: all 0.3s ease;
        &:hover {
          color: white;
          background: var(--primary-color);
        }
      }
    }
  }
  .bottom__appbar {
    display: none;
    height: 56px;
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 1;
    background: white;
    box-shadow: 4px -12px 10px 0px rgba(0, 0, 0, 0.03);
    a {
      width: 100%;
      border-top: 2px solid transparent;
      transition: all 0.3s ease;
      &.active {
        border-top: 2px solid var(--primary-color);
      }
    }
  }
  @media (max-width: 600px) {
    .menu__list {
      display: none;
    }
    .bottom__appbar {
      display: flex;
    }
  }
  @media (min-width: 600px) {
    .mini__menu__container {
      display: none;
    }
  }
}

// body container 
.body__container {
  max-width: 1250px;
  margin: 0 auto;
  padding: 16px;
}

// posts list
.posts__lists {
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  .post__card {
      background: white;
      box-shadow: 4px 12px 10px 0px rgba(0, 0, 0, 0.031);

      h2 {
        font-size: 16px;
        text-transform: capitalize;
        @extend .elipsis;
        -webkit-line-clamp: 1;
      }
  
      p {
        font-size: 12px;
        @extend .elipsis;
        -webkit-line-clamp: 3;
        color: rgba(0, 0, 0, 0.75);
      }
    }
}

// photos
.photos__list {
  grid-template-columns: repeat(auto-fit, minmax(8%, 1fr));
  div {
    width: 100%;
    height: 100px;
    overflow: hidden;
    
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.5);
      }
    }
  }
}

// users
.users__list {
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  .user__card {
      background: white;
      box-shadow: 0 5px 15px 0 rgb(156 39 176 / 10%);
    }
}

// button 
button {
  border: none;
  outline: none;
  padding: 8px 16px;
}

.courses__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 16px;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border-radius: 8px;
      height: 140px;
      background: white;
      color: var(--primary-color);
      cursor: pointer;
      transition: all 0.3s ease;
      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
      &:hover {
        box-shadow: 0 5px 15px 0 rgb(156 39 176 / 10%);
      }
    }
  }

}

// join us 
.joun__us__container {
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  .join__us__card {
    box-shadow: 0 5px 15px 0 rgb(156 39 176 / 10%);
  }
}

// contact form
.contact__form {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
}

// user card
.testimonial__card {
  min-height: 100px;
  min-width: 260px;
  background: white;
  transition: all 0.3s ease;
  p {
    @extend .elipsis;
    -webkit-line-clamp: 3;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  
}

// loader
.loader__container {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.25);
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, var(--primary-color) 94%, #0000) top/6px 6px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
  animation: loader__animation 1s infinite linear;
}

@keyframes loader__animation {
  100% {
    transform: rotate(1turn)
  }
}

// form styling
.form__group {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  @media screen and (max-width: 760px) {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  }
}