button {
    height: 36px;
    outline: none;
    border: none;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: var(--primary-color);
    color: white;

    &.secondary {
        background: white;
        color: rgba(0, 0, 0, 0.5);
        border: .5px solid rgba(0, 0, 0, 0.5);
    }

    &:disabled {
        cursor: not-allowed !important;
    }

    /* link button */
    &.link {
        color: var(--primary-color);
        background: #FFFFFF;
        border: 1px solid var(--primary-color);
    }

    &.link:disabled {
        color: #A9ACB2 !important;
    }

    &.icon {
        width: 36px; 
        height: 36px;
        border-radius: 50%;
        padding: 0;
    }

    &.square {
        height: 36px;
        min-width: 36px;
        padding: 0;
    }
}