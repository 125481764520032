input, textarea, select {
    min-height: 36px;
    width: 100%;
    border: .5px solid transparent;
    outline: none;
    padding: 12px;
    border-radius: 4px;
    transition: all 0.3s ease;
    font-size: 12px;
    background: rgba(128, 128, 128, 0.075);
    &:focus {
        background: white;
        border: .5px solid var(--primary-color);
        // box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.05);
    }
}
// select placeholder
option[value=''][disabled] {
    display: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 0;
    &::-ms-expand {
        display: none;
    }
}