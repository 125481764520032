.auth__popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Semi-transparent backdrop
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; // Ensure it's above other content

    // Popup Container
    .popup {
        width: 100%;
        margin: 24px;
        background-color: white;
        border-radius: 8px; // Modern rounded corners
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); // Subtle shadow
        padding: 32px; // Consistent spacing
        max-width: 480px; // Responsive width limit
        transition: transform 0.3s ease-in-out;
        animation: x 250ms linear;
    }
}

 @keyframes x {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
     100% {
        opacity: 1;
        transform: scale(1);
     }
 }