// 9855069174
.custom__toast__container {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000; // Ensure it's on top

    // Individual Toast Styles
    .custom__toast {
        max-width: 500px;
        min-width: 100px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        color: #333;

        // Close Button (optional)
        &__close {
            padding: 0;
            font-size: 16px;
            width: 24px !important;
            height: 24px !important;
            color: red;
            background: transparent;
        }
    }
}
